import React from "react";
import { Button, Box, Typography } from "@mui/material";
import styled from "styled-components";
import Input from "../widgets/input";
import { useForm } from "react-hook-form";
import FormError from "../widgets/form-error";
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import useThemeBase from "../../hooks/useThemeBase";

const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  width: 100%;
  max-width:450px;
  margin:auto;
`;

const Field = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
  width: 100%;
  margin:auto;
`;

const LoginForm = ({ onsubmit }) => {
  const themeBase = useThemeBase()
  const { authStore } = useSelector(store => store)


  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: 'onChange' })


  const submitHandler = (data) => {
    onsubmit(data)
  }





  return (
    <Form
      component="form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Typography variant="h4">Login</Typography>
      <Field>
        <Input label="Username" name="username" {...register('username', { required: true })} />
        {errors.username && <FormError>Username is required.</FormError>}
      </Field>
      <Field>
        <Input type="password" label="Password" name="password" {...register('password', { required: true })} />
        {errors.password && <FormError>Passowrd is required.</FormError>}
      </Field>
      <NavLink to="/" style={{ color: themeBase.common.text, textDecoration: "none", marginLeft: "auto", display: "block", width: "max-content" }}>
        <span>Forgot Passowrd?</span>
      </NavLink>
      <Button
        size="large"
        variant="contained"
        type="submit"
        disabled={!isDirty || !isValid || authStore?.loading}
      >
        Login
      </Button>
    </Form>
  );
};

export default LoginForm;
