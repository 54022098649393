import {
  Avatar,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Rating,
  Skeleton
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CustomCard from "../widgets/custom-card";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFeedbacks,
  handleLiveFeedbackSubscription,
  handleLiveTopFeedbackSubscription,
  topThreeFeedbacksApi
} from "../../api/feedbacks";
import useApi from "../../hooks/useApi";
import * as actionTypes from "../../store/actionTypes";
import CustomAlert from "../widgets/custom-alert";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  column-gap: 20px;
  margin: auto;
  padding: 20px 10px 0px 10px;
`;

const Actions = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  column-gap: 20px;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const TopFeedbacks = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { feedbackStore } = useSelector((store) => store);
  const { feedbacks } = feedbackStore;

  const [error, setError] = useState(null);

  const [{ loading }] = useApi(fetchFeedbacks, {
    onSuccess: (res) => {
      if (res) {
        handleLiveFeedbackSubscription(dispatch, res);
      }
    },
    onError: (error) => setError(error)
  });


  const loadingElement = (
    <>
      <Skeleton sx={{ height: "100px", width: "80%", mx: "auto" }} />
      <Skeleton sx={{ height: "100px", width: "80%", mx: "auto" }} />
      <Skeleton sx={{ height: "100px", width: "80%", mx: "auto" }} />
    </>
  );

  return (
    <>
      <CustomCard header="Feedbacks">
        {loading ? (
          loadingElement
        ) : (
          <Body>
            <List sx={{ width: "100%" }}>
              {feedbacks?.map((feedback, key) => {
                if (key < 3) {
                  return (
                    <React.Fragment key={key}>
                      <ListItem alignItems="flex-start" sx={{ paddingLeft: 0 }}>
                        <ListItemAvatar>
                          <Avatar>
                            {feedback.fullName
                              .split(" ")
                              ?.map(
                                (n, i) =>
                                  i < 2 && n.substring(0, 1).toUpperCase()
                              )}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={feedback.fullName}
                          secondary={
                            <React.Fragment>
                              <span style={{ display: "block" }}>
                                <Rating
                                  name="rating"
                                  value={feedback.rating}
                                  readOnly
                                />
                              </span>
                              {feedback.review}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      {key < 2 && <Divider variant="inset" component="li" />}
                    </React.Fragment>
                  );
                }
              })}
            </List>
            <Actions>
              <Button
                type="button"
                variant="text"
                onClick={() => navigate("/feedbacks")}
              >
                See All
              </Button>
            </Actions>
          </Body>
        )}
      </CustomCard>
      <CustomAlert
        open={!!error}
        onClose={() => setError(null)}
        type="error"
        duration={2000}
      >
        Something went wrong
      </CustomAlert>
    </>
  );
};

export default TopFeedbacks;
