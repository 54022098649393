import Parse from "./parse";

export const fetchDownloadStats = async () => {
  try {
    const statsQuery = new Parse.Query("downloadedFiles");
    let subscription = await statsQuery.subscribe();
    let stats = await statsQuery.limit(9999).descending("createdAt").find();
    stats = stats.map((user) => {
      return { ...user.attributes, id: user.id };
    });
    return stats;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
