import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import useApi from "../../hooks/useApi";
import TopLocationCard from "./top-location-card";
import BoothCard from "./booth-card";
import SelfieBoothCard from "./selfie-booth-card";
import { LOCATIONS } from "../../utils/constants";
import LobbyIcon from "@mui/icons-material/Home";
import AuditoriumIcon from "@mui/icons-material/CameraOutdoor";
import ExhibitionIcon from "@mui/icons-material/Store";
import NetworkIcon from "@mui/icons-material/ConnectWithoutContact";
import styled from "styled-components";
import SelfieBoothIcon from "@mui/icons-material/PhotoCamera";
import BoothIcon from "@mui/icons-material/AccountBalance";
import * as actionTypes from "../../store/actionTypes";
import Card from "../widgets/card"

import {
  getLocationSessions,
  handleLocationSessionSubscription
} from "../../api/location";
import LocationVisitorsList from "./location-visitors-list";
import CustomAlert from "../widgets/custom-alert";
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import MovieIcon from '@mui/icons-material/Movie';

const Header = styled(Grid)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const LocationCard = ({ location, id, type }) => {
  const dispatch = useDispatch();
  const { locationStore } = useSelector((store) => store);

  const [error, setError] = useState(null);

  const [isOnlineUsersListOpen, setIsOnlineUsersListOpen] = useState(false);

  const [locationLiveUsers, setLocationLiveUsers] = useState([]);

  const [{ loading }] = useApi(getLocationSessions, {
    onSuccess: (res) => {
      if (res) handleLocationSessionSubscription(dispatch, res, location.id);
    },
    onError: (error) => {
      setError(error);
    },
    params: location.sessionId
  });

  useEffect(() => {
    let liveUsers = [];
    locationStore[location.id]?.forEach((sessionUser) => {
      if (sessionUser.isLive) liveUsers.push(sessionUser);
    });
    setLocationLiveUsers(liveUsers);
  }, [locationStore.sessionChanged]);

  const closeOnlineUsersList = (data) => {
    setIsOnlineUsersListOpen(false);
  };


  const getLocationCard = () => {
    switch (type) {
      case "top_location":
        return (
          <TopLocationCard
            liveUsers={locationLiveUsers}
            totalLiveUsersCount={locationStore?.liveUsers?.length}
            location={location}
            openUsersList={() => setIsOnlineUsersListOpen(true)}
            loading={loading}
            id={id}
          />
        );

      case "booth":
        return (
          <BoothCard
            liveUsers={locationLiveUsers}
            totalLiveUsersCount={locationStore?.liveUsers?.length}
            location={location}
            openUsersList={() => setIsOnlineUsersListOpen(true)}
            loading={loading}
          />
        );

      case "selfie_booth":
        return (
          <SelfieBoothCard
            liveUsers={locationLiveUsers}
            totalLiveUsersCount={locationStore?.liveUsers?.length}
            location={location}
            openUsersList={() => setIsOnlineUsersListOpen(true)}
            loading={loading}
          />
        );

      default:
        return <div></div>;
    }
  };

  return (
    <>
      <LocationVisitorsList
        usersList={locationStore[location.id]}
        open={isOnlineUsersListOpen}
        onClose={closeOnlineUsersList}
        title={"Users in " + location?.name}
        location={location.name}
      />
      {getLocationCard()}
      <CustomAlert
        open={!!error}
        onClose={() => setError(null)}
        type="error"
        duration={2000}
      >
        {"Something went wrong"}
      </CustomAlert>
    </>
  );
};

const topLocationsIds = ["lobby", "auditorium", "exhibition", "networking", "engagement_zone", "helpdesk", "selfie_booth1", "watchOnDemand"];
const TOP_LOCATIONS = LOCATIONS.filter((location) =>
  topLocationsIds.includes(location.id)
).map((location) => {
  let icon = "";
  switch (location.id) {
    case "lobby":
      icon = <LobbyIcon />;
      break;

    case "auditorium":
      icon = <AuditoriumIcon />;
      break;

    case "exhibition":
      icon = <ExhibitionIcon />;
      break;

    case "networking":
      icon = <NetworkIcon />;
      break;

    case "engagement_zone":
      icon = <AssuredWorkloadIcon />;
      break;

    case "helpdesk":
      icon = <HelpCenterIcon />;
      break;

    case "selfie_booth1":
      icon = <SelfieBoothIcon />;
      break;

    case "watchOnDemand":
      icon = <MovieIcon />;
      break;

    default:
      break;
  }
  return {
    ...location,
    icon
  };
});

const boothsIds = ["booth1", "booth2", "booth3", "booth4", "booth5", "booth6"];
const BOOTH_LOCATIONS = LOCATIONS.filter((location) =>
  boothsIds.includes(location.id)
);

const selfieBoothsIds = [
  "selfie_booth",
  "selfie_booth1",
  "selfie_booth2",
  "selfie_booth3"
];
const SELFIE_BOOTH_LOCATIONS = LOCATIONS.filter((location) =>
  selfieBoothsIds.includes(location.id)
);

const LocationGrid = () => {
  const dispatch = useDispatch();
  const { locationStore } = useSelector((store) => store);

  const [renderIndex, setRenderIndex] = useState(0);

  useEffect(() => {
    if (renderIndex > 0)
      dispatch({ type: actionTypes.CHECK_AND_UPDATE_LOCATION_SESSIONS });
    setRenderIndex((prev) => prev + 1);
  }, [locationStore?.liveUsers?.length]);

  const topLocationsGrid = () => {
    return TOP_LOCATIONS?.map((location, i) => {
      return (
        <Grid item xs={12} md={6} xl={3} key={"top_location" + i}>
          <LocationCard location={location} id={i} type="top_location" />
        </Grid>
      );
    });
  };

  const boothsGrid = () => {
    return BOOTH_LOCATIONS?.map((booth, i) => {
      return (
        <Grid item xs={6} md={4} lg={3} xl={2} key={"booth" + i}>
          <LocationCard location={booth} type="booth" />
        </Grid>
      );
    });
  };

  const SelfieBoothsGrid = () => {
    return SELFIE_BOOTH_LOCATIONS?.map((selfieBooth, i) => {
      return (
        <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
          <LocationCard location={selfieBooth} type="selfie_booth" />
        </Grid>
      );
    });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ minHeight: "100%", justifyContent: "center" }}
      >
        {topLocationsGrid()}
      </Grid>
      {!!BOOTH_LOCATIONS.length && <Card>
        <Grid
          container
          spacing={2}
          sx={{
            minHeight: "100%",
            justifyContent: "center",
            p: 2
          }}
        >
          <Header item xs={12}>
            <Typography variant="h5">Visitors in Booths</Typography>
            <BoothIcon />
          </Header>
          {boothsGrid()}
        </Grid>
      </Card>}
      {false && !!SELFIE_BOOTH_LOCATIONS.length && <Card>
        <Grid
          container
          spacing={2}
          sx={{
            minHeight: "100%",
            justifyContent: "center",
            p: 2
          }}
        >
          <Header item xs={12}>
            <Typography variant="h5">Visitors in Selfie Booths</Typography>
            <SelfieBoothIcon />
          </Header>
          {SelfieBoothsGrid()}
        </Grid>
      </Card>}
    </>
  );
};

export default LocationGrid;
