import React, { useState } from "react";
import { fetchDownloadStats, handleStatsSubscription } from "../../api/stats";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../hooks/useApi";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Avatar, Skeleton, Tooltip } from "@mui/material";
import CustomCard from "../widgets/custom-card";
import styled from "styled-components";
import * as actionTypes from "../../store/actionTypes";
import CustomAlert from "../widgets/custom-alert";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 180px);
  column-gap: 20px;
  margin: auto;
  padding: 20px 10px 0px 10px;
  overflow-y: auto;
`;

const Stats = () => {
  const dispatch = useDispatch();
  const { statsStore } = useSelector((store) => store);

  const [error, setError] = useState(null);

  const [{ loading }] = useApi(fetchDownloadStats, {
    onSuccess: (res) => {
      dispatch({
        type: actionTypes.SET_STATS,
        stats: res
      });
    },
    onError: (error) => setError(error)
  });

  const columns = [
    {
      field: "filename",
      minWidth: 300,
      headerName: "Filename",
      renderCell: (params) => (
        <Tooltip title={params.row.filename} placement="top" arrow>
          <span>{params.row.filename}</span>
        </Tooltip>
      )
    },
    {
      field: "fullName",
      minWidth: 150,
      headerName: "Downloaded By"
    },
    {
      field: "email",
      minWidth: 250,
      headerName: "Email"
    },
    {
      field: "createdAt",
      minWidth: 350,
      headerName: "Downloaded At (dd/mm/yyyy - hh:mm:ss)",
      renderCell: (params) => new Date(params.row.createdAt).toLocaleDateString() + " - " + new Date(params.row.createdAt).toLocaleTimeString()
    }
  ];

  return (
    <>
      <CustomCard header="Download Stats">
        {loading ? (
          <Skeleton style={{ width: "100%", height: "100px" }} />
        ) : (
          <Body>
            <DataGrid
              rows={statsStore.stats}
              columns={columns}
              components={{
                Toolbar: GridToolbar
              }}
              componentsProps={{
                toolbar: {
                  csvOptions: {
                    fileName: `Download Stats`
                  }
                }
              }}
            />
          </Body>
        )}
      </CustomCard>
      <CustomAlert
        open={!!error}
        onClose={() => setError(null)}
        type="error"
        duration={2000}
      >
        Something went wrong
      </CustomAlert>
    </>
  );
};

export default Stats;
