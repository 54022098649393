import { Delete } from "@material-ui/icons";
import { Button, IconButton, Stack } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import useThemeBase from "../../hooks/useThemeBase";
import VideoPlayer from "./video-player";

const VideoInputWrapper = styled(Stack)`
    align-items:center;
    width:100%;
`
const VideoWrapper = styled(Stack)`
    align-items:center;
    justify-content:center;
    padding:20px;
    width:100%;
    border:1px dashed silver;
    border-radius:4px;
    cursor:pointer;
    gap:5px;
`


const VideoInput = ({ onChange, url }) => {

    const themeBase = useThemeBase()

    const inputRef = React.useRef();

    const [source, setSource] = React.useState(url);
    const [file, setFile] = React.useState();

    const handleFileChange = (event) => {
        const fileData = event.target.files[0];
        const url = URL.createObjectURL(fileData);
        setSource(url);
        setFile(fileData);
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    useEffect(() => {
        onChange(file)
    }, [file])

    useEffect(() => {
        setSource(url)
    }, [url])

    const deleteVideo = () => {
        inputRef.current.value = ''
        setFile('')
        setSource('')
    }

    return (
        <VideoInputWrapper className="VideoInput">
            <VideoWrapper className="VideoInput_footer">

                <Stack direction="row" justifyContent="space-between" alignItems="center" gap="5px" width="100%">
                    {(file && !url) &&
                        <>
                            <span>{file.name}</span>
                            <IconButton onClick={deleteVideo}><Delete style={{ color: themeBase.error.main }} /></IconButton>
                        </>
                    }
                </Stack>
                {!source ? "No Video Selected" :
                    <VideoPlayer src={source} props={{ autoPlay: false }} />
                }
                {(!file || url) && <Button onClick={handleChoose}>
                    {url ? "Change Video" : "Select a Video"}
                </Button>}
            </VideoWrapper>
            <input
                ref={inputRef}
                style={{ visibility: "hidden", height: 0 }}
                type="file"
                onChange={handleFileChange}
                accept=".mov,.mp4"
            />

        </VideoInputWrapper>
    );
}

export default VideoInput
