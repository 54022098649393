import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { dialogClasses, IconButton, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import mStyled from "@mui/material/styles/styled";
import { customTheme } from '../../utils/constants';
import useThemeBase from '../../hooks/useThemeBase';


const Title = styled(DialogTitle)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:${props => props.themeBase.spacing[2]} ${props => props.themeBase.spacing[2]} 0 ${props => props.themeBase.spacing[2]} !important;
`

const CustomDialog = mStyled(Dialog)(({ theme }) => ({
  [`.${dialogClasses.paperWidthFalse}`]: {
    width:"80vw",
    maxWidth:"800px",
    background:"none",
    maxHeight: "unset"
  }
}));


const Popup = ({ onClose, selectedValue, open, children, title, loading }) => {
  const themeBase = useThemeBase()
  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <>
      <CustomDialog onClose={handleClose} open={open} maxWidth={false}>
        <div className="overlay" style={{background: themeBase.layer[2], position: "relative"}}>
          {loading && <LinearProgress style={{width:"100%", position: "absolute", top:0, left:0}} />}
        {title && (
          <>
            <Title themeBase={themeBase}>
              {title}
              <IconButton sx={{ float: "right" }} onClick={handleClose}><CloseIcon /></IconButton>
            </Title>
          </>
        )
        }
        <div style={{ padding: themeBase.spacing[2], maxHeight:"80vh", overflow: "auto" }}>
          {children}
        </div>
          </div>
      </CustomDialog>
    </>
  );
}

export default Popup
