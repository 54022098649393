import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../hooks/useApi";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Avatar, Skeleton, Tooltip } from "@mui/material";
import CustomCard from "../widgets/custom-card";
import styled from "styled-components";
import * as actionTypes from "../../store/actionTypes";
import CustomAlert from "../widgets/custom-alert";
import { fetchShareBusinessOpportunityResponses } from "../../api/share-business-opportunity";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 180px);
  column-gap: 20px;
  margin: auto;
  padding: 20px 10px 0px 10px;
  overflow-y: auto;
`;

const ShareBusinessOpportunity = () => {
  const dispatch = useDispatch();
  const { sBOStore } = useSelector((store) => store);

  const [error, setError] = useState(null);

  const [{ loading }] = useApi(fetchShareBusinessOpportunityResponses, {
    onSuccess: (responses) => {
      dispatch({ type: actionTypes.SET_SHARE_BUSINESS_OPPORTUNITY, responses });
    },
    onError: (error) => setError(error)
  });

  const columns = [
    {
      field: "fullName",
      minWidth: 150,
      headerName: "Fullname",
      renderCell: (params) => params.row.user.firstName + ' ' + (params.row.user.lastName || '')
    },
    {
      field: "accountName",
      minWidth: 150,
      headerName: "Account Name",
      renderCell: (params) => {
        const answer = params.row.data.filter(field => field.id === "accountName")[0]?.answer || ''
        return <span>{answer}</span>
      }
    },
    {
      field: "briefAboutOpportunity",
      minWidth: 300,
      headerName: "Brief About Opportunity",
      renderCell: (params) => {
        const answer = params.row.data.filter(field => field.id === "briefAboutOpportunity")[0]?.answer || ''
        return <Tooltip title={answer} placement="top" arrow>
          <span>{answer}</span>
        </Tooltip>
      }
    },
    {
      field: "country",
      minWidth: 150,
      headerName: "Country",
      renderCell: (params) => {
        const answer = params.row.data.filter(field => field.id === "country")[0]?.answer || ''
        return <span>{answer}</span>
      }
    },
    {
      field: "email",
      minWidth: 150,
      headerName: "Email",
      renderCell: (params) => params.row.user.userId
    },
    {
      field: "phone",
      minWidth: 150,
      headerName: "Phone",
      renderCell: (params) => params.row.user.phone
    },

  ];


  return (
    <>
      <CustomCard header="Share Business Opportunity Responses">
        {loading ? (
          <Skeleton style={{ width: "100%", height: "100px" }} />
        ) : (
          <Body>
            <DataGrid
              rows={sBOStore.sBOResponses}
              columns={columns}
              components={{
                Toolbar: GridToolbar
              }}
              componentsProps={{
                toolbar: {
                  csvOptions: {
                    fileName: `Share Business Opportunity Responses`,
                  }
                }
              }}
            />
          </Body>
        )}
      </CustomCard>
      <CustomAlert
        open={!!error}
        onClose={() => setError(null)}
        type="error"
        duration={2000}
      >
        Something went wrong
      </CustomAlert>
    </>
  );
};

export default ShareBusinessOpportunity;
