import React from "react"
import styled from "styled-components";


const Wrapper = styled.div`
  ${(props) =>
    props.open
      ? `
    display:block;
    `
      : `
    display:none;
    `}
  width:100vw;
  height: 100vh;
  position: fixed;
  background:rgba(0,0,0,0.5);
  backdrop-filter:blur(30px);
  z-index: 1150;
`;
const Overlay = ({onClose, isOpen}) => {
    return <Wrapper onClick={onClose} open={isOpen} />
}

export default Overlay