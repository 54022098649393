import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { darkThemeBase, lightThemeBase } from "../utils/constants"


const useThemeBase = () => {
    const { pageStore } = useSelector(store => store)
    const [themeBase, setThemeBase] = useState(darkThemeBase)

    useEffect(() => {
        if (pageStore.themeMode === "light") {
            setThemeBase(lightThemeBase)
        } else {
            setThemeBase(darkThemeBase)
        }
    }, [pageStore.themeMode])

    return themeBase
}

export default useThemeBase