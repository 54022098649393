import Login from "./pages/login";
import Dashboard from "./pages/home";
import Feedbacks from "./pages/feedbacks";
import Stats from "./pages/stats";
import Quiz from "./pages/quiz";
import ShareBusinessOpportunityPage from "./pages/share-business-opportunity";
import WatchOnDemandPage from "./pages/watch-on-demand";


const routes = ({ loggedIn }) => {

  return [
    {
      path: "/login",
      name: "Login",
      component: Login,
      hideNavBar: true,
      redirect: {
        condition: !!loggedIn,
        pathTo: "/dashboard",
        hideNavBar: false
      }
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      hideNavBar: false,
      redirect: {
        condition: !loggedIn,
        pathTo: "/login",
        hideNavBar: true
      }
    },
    {
      path: "/feedbacks",
      name: "Feedbacks",
      component: Feedbacks,
      hideNavBar: false,
      redirect: {
        condition: !loggedIn,
        from: "/feedbacks",
        pathTo: "/login",
        hideNavBar: true
      }
    },
    {
      path: "/stats",
      name: "Stats",
      component: Stats,
      hideNavBar: false,
      redirect: {
        condition: !loggedIn,
        pathTo: "/login",
        hideNavBar: true
      }
    },
    {
      path: "/quiz",
      name: "Quiz",
      component: Quiz,
      hideNavBar: false,
      redirect: {
        condition: !loggedIn,
        pathTo: "/login",
        hideNavBar: true
      }
    },
    {
      path: "/shareBusinessOpportunity",
      name: "Share Business Opportunity",
      component: ShareBusinessOpportunityPage,
      hideNavBar: false,
      redirect: {
        condition: !loggedIn,
        pathTo: "/login",
        hideNavBar: true
      }
    },
    {
      path: "/watchOnDemand",
      name: "Watch On Demand",
      component: WatchOnDemandPage,
      hideNavBar: false,
      redirect: {
        condition: !loggedIn,
        pathTo: "/login",
        hideNavBar: true
      }
    },
    {
      path: "*",
      component: Dashboard,
      redirect: {
        condition: !loggedIn,
        pathTo: "/login",
        hideNavBar: false
      }
    }
  ];
};

export default routes;
