import { ButtonGroup, Checkbox, CircularProgress, Divider, FormControlLabel, IconButton, Skeleton, Stack, Typography } from "@mui/material"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useThemeBase from "../../hooks/useThemeBase";
import { updateWatchOnDemandConfiguration, uploadFile } from "../../api/watch-on-demand";
import { useState } from "react";
import Popup from "../widgets/popup";
import VideoForm from "./video-form";
import VideoPlayer from "./video-player";
import styled from "styled-components";
import CustomAlert from "../widgets/custom-alert";
import { isMobile } from "react-device-detect";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  align-items:center;
  justify-content:center;
  position:absolute;
  top:0;left:0;
  background: rgba(0,0,0,.2);
  backdrop-filter:blur(5px);
  z-index:1;
  border-radius:4px;
`;



const VideosList = ({ watchOnDemand, loading, refreshList }) => {

    const themeBase = useThemeBase()
    const [quickActionloading, setQuickActionLoading] = useState(false)
    const [editConfig, setEditConfig] = useState()
    const [playVideo, setPlayVideo] = useState()
    const [actionLoading, setActionLoading] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const editFileHandler = (key, file) => {
        setEditConfig({ collectionKey: key, file })
    }

    const quickUpdateFileHandler = async (collectionId, id, makeAvailable) => {

        try {
            setQuickActionLoading(true)
            await updateWatchOnDemandConfiguration({
                ...watchOnDemand.configuration,
                [collectionId]: {
                    ...watchOnDemand.configuration[collectionId],
                    files: [...watchOnDemand.configuration[collectionId].files.map(file => {
                        if (file.id === id) {
                            return {
                                fileName: file.fileName,
                                fileURL: file.fileURL,
                                makeAvailable: makeAvailable,
                                id
                            }
                        }
                        else return file
                    })]
                }
            })
            setQuickActionLoading(false)
            refreshList()
        } catch (error) {
            setQuickActionLoading(false)
            console.error(error)
        }
    }
    const deleteFileHandler = async (collectionId, id) => {

        try {
            setQuickActionLoading(true)
            await updateWatchOnDemandConfiguration({
                ...watchOnDemand.configuration,
                [collectionId]: {
                    ...watchOnDemand.configuration[collectionId],
                    files: [...watchOnDemand.configuration[collectionId].files.filter(file => file.id !== id)]
                }
            })
            setQuickActionLoading(false)
            refreshList()
        } catch (error) {
            setQuickActionLoading(false)
            console.error(error)
        }
    }
    const updateCollectionHandler = async (collectionId, isDayAvailable) => {

        try {
            setQuickActionLoading(true)
            await updateWatchOnDemandConfiguration({
                ...watchOnDemand.configuration,
                [collectionId]: {
                    ...watchOnDemand.configuration[collectionId],
                    isDayAvailable
                }
            })
            setQuickActionLoading(false)
            refreshList()
        } catch (error) {
            setQuickActionLoading(false)
            console.error(error)
        }
    }



    const updateFileHandler = async (collectionId, updatedFile, rawFile) => {
        const config = watchOnDemand.configuration
        try {
            setActionLoading(true)
            const fileURL = rawFile ? await uploadFile(rawFile) : updatedFile?.fileURL
            await updateWatchOnDemandConfiguration({
                ...config,
                [collectionId]: {
                    ...config[collectionId],
                    files: [...config[collectionId].files.map(file => {
                        if (file.id === updatedFile.id) {
                            return {
                                ...updatedFile,
                                fileURL,
                            }
                        }
                        else return file
                    })]
                }
            })
            setActionLoading(false)
            setEditConfig(false)
            return true
        } catch (error) {
            setActionLoading(false)
            console.error(error)
            setError("Something went wrong!")
        }
    }


    const renderList = (collection, groupBy) => {
        return !!collection?.files.length && (<div>
            <Stack direction="row" sx={{ px: "10px", justifyContent: "space-between", alignItems: "center", }}>
                <Typography variant="subtitle1">{groupBy}</Typography>
                <FormControlLabel control={<Checkbox size={isMobile ? "small" : "medium"} checked={collection.isDayAvailable} onChange={(_, checked) => updateCollectionHandler(groupBy, checked)} />} label="Is Day Available? " labelPlacement="start" />
            </Stack>
            <Stack gap="5px">
                {collection?.files.map(file => {
                    return <Stack direction={isMobile ? "column" : "row"} gap="10px" sx={{ p: isMobile ? "10px" : "3px 10px", justifyContent: "space-between", alignItems: "center", background: themeBase.layer[1], borderRadius: '4px' }}>
                        <Typography variant="body2" align="left" width="100%">{file.fileName}</Typography>
                        {isMobile && <Divider flexItem orientation="horizontal" />}
                        <Stack direction="row" gap="5px" alignItems="center" justifyContent="space-between" whiteSpace="nowrap" width={isMobile ? "100%" : "max-content"}>
                            <ButtonGroup variant="outlined" aria-label="outlined button group" >
                                <IconButton size={isMobile ? "small" : "medium"} onClick={() => setPlayVideo(file)}><PlayArrowIcon style={{ color: themeBase.success.main }} /></IconButton>
                                <IconButton size={isMobile ? "small" : "medium"} disabled={!(collection.isDayAvailable)} onClick={() => editFileHandler(groupBy, file)}><EditIcon sx={{ color: collection.isDayAvailable && themeBase.secondary.main }} /></IconButton>
                                <IconButton size={isMobile ? "small" : "medium"} disabled={!(collection.isDayAvailable)} onClick={() => deleteFileHandler(groupBy, file.id)}><DeleteIcon sx={{ color: collection.isDayAvailable && themeBase.error.main }} /></IconButton>
                            </ButtonGroup>
                            <Divider flexItem orientation="vertical" />
                            <FormControlLabel control={<Checkbox size={isMobile ? "small" : "medium"} disabled={!collection.isDayAvailable} checked={file.makeAvailable} onChange={(_, checked) => quickUpdateFileHandler(groupBy, file.id, checked)} />} label="Make Available? " labelPlacement="start" />
                        </Stack>
                    </Stack >
                })}
            </Stack>
        </div >)
    }


    const renderListCollection = () => (
        <div style={{ position: "relative" }}>
            {quickActionloading && <LoadingWrapper><CircularProgress /></LoadingWrapper>}
            {Object.keys(watchOnDemand?.configuration)?.map(key => renderList(watchOnDemand.configuration[key], key))}
        </div>)

    return loading ? <Skeleton style={{ width: "100%", height: "100px" }} /> : <>
        {watchOnDemand && watchOnDemand.configuration && renderListCollection()}
        <VideoForm
            editConfig={editConfig}
            collectionKey={editConfig?.collectionKey}
            file={editConfig?.file}
            config={watchOnDemand?.configuration}
            title="Edit Video"
            open={!!editConfig}
            loading={actionLoading}
            error={error}
            onError={(error) => setError(error)}
            onSuccess={() => {
                setSuccess(true)
                refreshList()
            }}
            onClose={() => {
                setEditConfig('')
            }}
            onUpdate={updateFileHandler}
        />
        <Popup title={playVideo?.fileName} open={!!playVideo} onClose={() => setPlayVideo(null)}>
            <VideoPlayer src={playVideo?.fileURL} />
        </Popup>
        <CustomAlert open={success} onClose={() => setSuccess(false)}>Updated Successfully</CustomAlert>
        <CustomAlert open={!!error} type="error" onClose={() => setError(null)}>{error}</CustomAlert>
    </>
}

export default VideosList