import Parse from "./parse";

export const fetchWatchOnDemandConfiguration = async () => {
    try {
        let configurationQuery = new Parse.Query('watchOnDemandConfiguration');
        let result = null;
        let configuration = await configurationQuery.find();
        if (configuration && configuration.length > 0) {
            result = { ...configuration[0].attributes, id: configuration[0].id };
        }
        return result;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const updateWatchOnDemandConfiguration = async (data) => {
    try {
        let configurationQuery = new Parse.Query('watchOnDemandConfiguration');
        let configuration = await configurationQuery.first();
        configuration.set('configuration', data)
        const result = await configuration.save()
        return result;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const uploadFile = async (file) => {
    let fileUpload = new Parse.File(
        `${file.name}`,
        file
    );
    fileUpload = await fileUpload.save();
    let fileURL = fileUpload._url
    return fileURL
}