import React, { useEffect } from "react";
import { withTheme } from "@rjsf/core";
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField
} from "@mui/material";
import Input from "../widgets/input";
import { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import useThemeBase from "../../hooks/useThemeBase";

const TextWidget = (props) => {

  return (
    <Input
      {...props}
      {...props.uiSchema["ui:options"].props}
      onChange={(e) => props.onChange(e.target.value)}
    />
  );
};

const SwitchWidget = (props) => {

  return (
    <FormControlLabel
      {...props}
      control={<Switch checked={props.value} />}
      {...props.uiSchema["ui:options"].props}
      onChange={(e) => props.onChange(!props.value)} />
  );
};

const SelectWidget = (props) => {
  return (
    <FormControl sx={{ minWidth: 160 }}>
      <InputLabel>
        {props.uiSchema["ui:options"].props.label || props.label}
      </InputLabel>
      <Select
        {...props}
        {...props.uiSchema["ui:options"].props}
        onChange={(e) => props.onChange(e.target.value)}
      >
        {props?.options.enumOptions.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const OptionsWidget = (props) => {
  const themeBase = useThemeBase();
  const changeHandler = (value, index) => {
    const newOptions = [
      ...props.value.map((option, i) => (i === index ? value : option))
    ];
    props.onChange(newOptions);
  };

  const addItem = () => {
    const newOptions = [...props.value, ""];
    props.onChange(newOptions);
  };

  const removeItem = (index) => {
    const newOptions = [...props.value.filter((_, i) => i !== index)];
    props.onChange(newOptions);
  };

  const itemUp = (value, index) => {
    if (index === 0) return;
    const oldUpperOptionValue = props.value.filter(
      (option, i) => i === index - 1
    )[0];
    const newOptions = [
      ...props.value.map((option, i) =>
        i === index ? oldUpperOptionValue : i === index - 1 ? value : option
      )
    ];
    props.onChange(newOptions);
  };
  const itemDown = (value, index) => {
    if (index === props.value.length - 1) return;
    const oldUpperOptionValue = props.value.filter(
      (option, i) => i === index + 1
    )[0];
    const newOptions = [
      ...props.value.map((option, i) =>
        i === index ? oldUpperOptionValue : i === index + 1 ? value : option
      )
    ];
    props.onChange(newOptions);
  };

  return (
    <Stack rowGap="10px" marginTop={themeBase.spacing[2]}>
      {props.value?.map((item, index) => {
        return (
          <Stack
            mt="10px"
            columnGap="2px"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            key={item + index}
          >
            <TextField
              autoFocus
              {...props}
              id={"Item" + index}
              style={{ width: "100%" }}
              variant="outlined"
              label={"Item " + index}
              value={item}
              onChange={(e) => changeHandler(e.target.value, index)}
            />
            <IconButton
              color="info"
              onClick={() => itemUp(item, index)}
              disabled={index === 0}
            >
              <ArrowUpwardIcon color="inherit" />
            </IconButton>
            <IconButton
              color="success"
              onClick={() => itemDown(item, index)}
              disabled={index === props.value.length - 1}
            >
              <ArrowDownwardIcon color="inherit" />
            </IconButton>
            <IconButton
              style={{ color: themeBase.error.main }}
              onClick={() => removeItem(index)}
            >
              <DeleteIcon />
            </IconButton>

          </Stack>
        );
      })}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={props.value.length > 0 ? "flex-end" : "center"}
      >
        {props.value.length > 0 ? (
          <Button color="secondary" variant="contained" onClick={addItem}>
            <AddIcon />
          </Button>
        ) : (
          <Button variant="outlined" onClick={addItem} startIcon={<AddIcon />}>
            Add An Item
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

const SchemaForm = ({
  schema,
  uiSchema,
  onSubmit,
  props,
  currentData,
  loading,
  validations
}) => {
  const [data, setData] = useState(currentData);

  const ThemedForm = withTheme({
    widgets: {
      textWidget: (props) => <TextWidget {...props} />,
      select: (props) => <SelectWidget {...props} />,
      optionsWidget: (props) => <OptionsWidget {...props} />,
      switchWidget: (props) => <SwitchWidget {...props} />,
    }
  });

  const submitHandler = (data, e) => {
    onSubmit(data.formData);
  };

  useEffect(() => {
    setData(currentData);
  }, [currentData]);

  return (
    <ThemedForm
      formData={data}
      onSubmit={submitHandler}
      schema={schema}
      uiSchema={uiSchema}
      idPrefix="schema_form"
      className="custom-form"
      noHtml5Validate
      validate={validations}
      {...props}
    >
      <Button
        disabled={loading}
        variant="contained"
        type="submit"
        sx={{ m: "10px", ml: "auto" }}
      >
        Submit
      </Button>
    </ThemedForm>
  );
};

export default SchemaForm;
