import Parse from "./parse";
import * as actionTypes from '../store/actionTypes'

export const updateStaticQuizConfig = async (config) => {
  try {

    const staticQuizConfig = await new Parse.Query('StaticQuizConfig').first()
    staticQuizConfig.set('allowedMultiAttempts', config.allowedMultiAttempts)
    const response = await staticQuizConfig.save()
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getStaticQuizConfig = async () => {
  try {

    const response = await new Parse.Query('StaticQuizConfig').first()
    return { ...response.attributes }
  } catch (error) {
    console.error(error)
    throw error
  }
}


export const createStaticQuestion = async (question) => {
  try {

    const StaticQuiz = Parse.Object.extend('StaticQuiz')
    const staticQuiz = new StaticQuiz()
    const response = await staticQuiz.save(question)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getStaticQuestions = async () => {
  try {
    const questionQuery = new Parse.Query("StaticQuiz");
    let questions = await questionQuery
      .limit(9999)
      .descending("updatedAt")
      .find();
    return questions;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const deleteStaticQuestion = async (questionId) => {
  try {
    const questionQuery = new Parse.Query("StaticQuiz");
    let questionToBeDeleted = await questionQuery
      .equalTo("objectId", questionId)
      .find();
    await questionToBeDeleted[0].destroy();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateStaticQuestion = async (questionData) => {
  try {
    const questionQuery = new Parse.Query("StaticQuiz");
    let questionToBeUpdated = await questionQuery
      .equalTo("objectId", questionData.id)
      .find();
    questionToBeUpdated[0].set("question", questionData.question);
    questionToBeUpdated[0].set("questionType", questionData.questionType);
    questionToBeUpdated[0].set("required", questionData.required);
    questionToBeUpdated[0].set("min", questionData.min);
    questionToBeUpdated[0].set("max", questionData.max);
    questionToBeUpdated[0].set("options", questionData.options);
    await questionToBeUpdated[0].save();
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const getStaticQuizAnswers = async () => {
  try {
    const answersQuery = new Parse.Query("StaticQuizAnswers")
    const subscription = await answersQuery.subscribe()
    const answersResponse = await answersQuery.find();
    const answers = answersResponse.map(ans => ({ ...ans.attributes, id: ans.id }))
    return { answers, subscription }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const handleLiveStaticAnswersSubscription = (response, dispatch) => {
  const { answers, subscription } = response
  dispatch({ type: actionTypes.SET_STATIC_ANSWERS, answers })

  try {
    subscription.on('create', answerObj => {
      dispatch({ type: actionTypes.UPDATE_STATIC_ANSWERS, answer: { ...answerObj.attributes, id: answerObj.id } })
    })
  } catch (error) {
    dispatch({ type: actionTypes.QUIZ_ACTION_FAIL, error })
  }
}


export const createQuestion = async ({
  question,
  questionType,
  options,
  location
}) =>
  await Parse.Cloud.run("quizQuestion", {
    question,
    questionType,
    options,
    location
  });


export const pushQuestion = async ({
  question,
  questionType,
  options,
  location,
  id
}) =>
  await Parse.Cloud.run("liveQuestion", {
    question,
    questionType,
    options,
    location,
    quizQuestionId: id
  });

export const getQuestions = async (location) => {
  try {
    const questionQuery = new Parse.Query("quizQuestion");
    let questions = await questionQuery
      .equalTo('location', location)
      .limit(9999)
      .descending("updatedAt")
      .find();
    return questions;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAnswers = async (questionId) => {
  try {
    const answersQuery = new Parse.Query("quizAnswer").equalTo("questionId", questionId)
    const subscription = await answersQuery.subscribe()
    const answersResponse = await answersQuery.find();
    const answers = answersResponse.map(ans => ({ ...ans.attributes, id: ans.id }))
    return { answers, subscription }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const handleLiveAnswersSubscription = (response, dispatch) => {
  const { answers, subscription } = response
  dispatch({ type: actionTypes.SET_ANSWERS, answers })

  try {
    subscription.on('create', answerObj => {
      dispatch({ type: actionTypes.UPDATE_ANSWERS, answer: { ...answerObj.attributes, id: answerObj.id } })
    })
  } catch (error) {
    dispatch({ type: actionTypes.QUIZ_ACTION_FAIL, error })
  }
}

export const deleteQuestion = async (questionId) => {
  try {
    const questionQuery = new Parse.Query("quizQuestion");
    let questionToBeDeleted = await questionQuery
      .equalTo("objectId", questionId)
      .find();
    await questionToBeDeleted[0].destroy();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateQuestion = async (questionData) => {
  try {
    const questionQuery = new Parse.Query("quizQuestion");
    let questionToBeUpdated = await questionQuery
      .equalTo("objectId", questionData.id)
      .find();
    questionToBeUpdated[0].set("question", questionData.question);
    questionToBeUpdated[0].set("questionType", questionData.questionType);
    questionToBeUpdated[0].set("options", questionData.options);
    await questionToBeUpdated[0].save();
  } catch (error) {
    console.error(error);
    throw error;
  }
};
