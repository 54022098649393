import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actionTypes from "../store/actionTypes";
import Dashboard from "../components/dashboard";
import Quiz from "../components/quiz";


const QuizPage = ({ navbar }) => {

  const dispatch = useDispatch();

 

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_CURRENT_PAGE,
      currentPage: { id: "quiz", navbar }
    });

  }, []);




  return <Quiz />
};

export default QuizPage;
