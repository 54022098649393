import { LOCATIONS } from "../../utils/constants";
import * as actionTypes from "../actionTypes";

const initialState = {
  liveUsers: [],
  loading: false,
  error: true,
  allUsers: [],
  sessionChanged:false
};

const updateLiveUsers = (state, userObject) => {
  let updatedLiveUsers = state.liveUsers;

  // checking if the updated user exist in the online users array
  const index = state.liveUsers?.findIndex((checkUser) => {
    return checkUser.userId === userObject.attributes.userId;
  });

  // if the user comes live but his details are present inside the online users array
  if (userObject.get("isLive") && index < 0) {
    // adding the details to the online users array
    updatedLiveUsers.unshift({ ...userObject.attributes });
  }

  // if the user goes offline but his details are present inside the online users array
  else if (!userObject.get("isLive") && index >= 0) {
    // removing the users details from the online users array
    updatedLiveUsers.splice(index, 1);
  }

  return {
    ...state,
    updatedLiveUsers
  };
};

const setLocationSessions = (state, locationId, sessions) => {
  let usersArray = [];
  let usersObject = {};
  sessions?.forEach((session) => {
    if (
      !!Object.keys(usersObject)?.filter(
        (emailKey) => emailKey === session.email
      ).length
    ) {
      usersObject[session.email].push(session);
    } else usersObject[session.email] = [session];
  });

  let twoHoursBefore = new Date(new Date().setHours(new Date().getHours() - 2));

  for (let emailKey in usersObject) {
    usersObject[emailKey].sort((a, b) => b.createdAt - a.createdAt);
    let user = {};
    let lastUpdatedAt;
    for (let i = 0; i < usersObject[emailKey].length; i++) {
      if (i === 0) lastUpdatedAt = usersObject[emailKey][i].updatedAt;
      usersObject[emailKey][i].duration =
        Math.round(+usersObject[emailKey][i].duration) || 0;
      if (
        (i === 0 &&
          !usersObject[emailKey][i].duration &&
          state.liveUsers.filter(
            (liveUser) => liveUser.email === usersObject[emailKey][i].email
          ).length > 0 && 
          new Date(usersObject[emailKey][i].createdAt) > twoHoursBefore) ||
        user.isLive
      ) {
        user.isLive = true;
      } else user.isLive = false;

      if (i > 0 && !usersObject[emailKey][i].duration) {
        user.duration = user.duration && user.duration;
      } else
        user.duration = user.duration
          ? usersObject[emailKey][i].duration + user.duration
          : usersObject[emailKey][i].duration;

      user = {
        ...usersObject[emailKey][i],
        ...user,
        updatedAt: lastUpdatedAt
      };
    }

    usersObject[emailKey] = user;

    usersArray.push(usersObject[emailKey]);
  }

  return {
    ...state,
    loading: false,
    error: false,
    sessionChanged: !state.sessionChanged,
    [locationId]: usersArray
  };
};

const updateLocationSession = (state, locationId, sessionItem) => {
  let userAlreadyExistInSession = false;
  let newSessionItem;
  let updatedSession = [];
  updatedSession = state[locationId].map((session) => {
    if (session.email === sessionItem.email) {
      userAlreadyExistInSession = true;
      newSessionItem = { ...session };
      newSessionItem.duration = Math.round(+newSessionItem.duration) || 0;
      newSessionItem.isLive = !sessionItem.duration ? true : false;
      newSessionItem.duration = !!sessionItem.duration
        ? Math.round(+sessionItem.duration) + newSessionItem.duration
        : newSessionItem.duration;
      newSessionItem.updatedAt = new Date();
      return newSessionItem;
    }
    return session;
  });

  if (!userAlreadyExistInSession) {
    updatedSession.push({
      ...sessionItem,
      isLive: true,
      id: sessionItem.sessionId
    });
  }

  return {
    ...state,
    loading: false,
    error: false,
    sessionChanged: !state.sessionChanged,
    [locationId]: updatedSession
  };
};


// const checkLocationSession = (state, locationId) => {
  
//   let newSessionItem;
//   let updatedSession = [];
//   updatedSession = state[locationId].map((session) => {
    
//       newSessionItem = { ...session };

//       newSessionItem.isLive = session.isLive && 
//       state.liveUsers?.filter((liveUser) => liveUser?.email === session?.email)?.length > 0 ?
//       true : false;

//       return newSessionItem;
//   });


//   return {
//     ...state,
//     loading: false,
//     error: false,
//     sessionChanged: !state.sessionChanged,
//     [locationId]: updatedSession
//   };
// };

const checkAndUpdateLiveSessions = (state) => {
  let newState = {};
  LOCATIONS.forEach((location) => {
    newState[location.id] = [];

    state[location.id]?.forEach((user) => {
      let updatedUser = {
        ...user,
        isLive: user.isLive &&
          state.liveUsers?.filter((liveUser) => liveUser?.email === user?.email)
            ?.length > 0
      };
      newState[location.id]?.push(updatedUser);
    });
  });
  
  return {
    ...state,
    ...newState,
    sessionChanged: !state.sessionChanged
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOCATION_ACTION_START:
      return { ...state, loading: true, error: false };

    case actionTypes.LOCATION_ACTION_FAIL:
      return { ...state, loading: false, error: action.error };

    case actionTypes.SET_ALL_USERS:
      return {
        ...state,
        loading: false,
        error: false,
        allUsers: action.allUsers
      };

    case actionTypes.SET_LIVE_USERS:
      return {
        ...state,
        loading: false,
        error: false,
        liveUsers: action.liveUsers
      };

    case actionTypes.UPDATE_LIVE_USERS:
      return updateLiveUsers(state, action.userObject);

    case actionTypes.SET_LOCATION_SESSIONS:
      return setLocationSessions(
        state,
        action.locationId,
        action.locationSessions
      );

    case actionTypes.UPDATE_LOCATION_SESSIONS:
      return updateLocationSession(
        state,
        action.locationId,
        action.sessionItem
      );

    case actionTypes.CHECK_AND_UPDATE_LOCATION_SESSIONS:
      return checkAndUpdateLiveSessions(state);

    default:
      return state;
  }
};

export default reducer;
