import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actionTypes from "../store/actionTypes";
import Dashboard from "../components/dashboard";


const Home = ({ navbar }) => {

  const dispatch = useDispatch();



  useEffect(() => {
    dispatch({
      type: actionTypes.SET_CURRENT_PAGE,
      currentPage: { id: "dashboard", navbar }
    });

  }, []);




  return <Dashboard />
};

export default Home;
