import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FeedbackIcon from "@mui/icons-material/Feedback";
import DownloadIcon from "@mui/icons-material/Download";
import DashboardIcon from '@mui/icons-material/Dashboard';
import QuizIcon from '@mui/icons-material/Quiz';
import { isMobile } from 'react-device-detect';
import useThemeBase from "../../hooks/useThemeBase";
import BusinessIcon from '@mui/icons-material/Business';
import MovieIcon from '@mui/icons-material/Movie';

const SidebarLargeScreen = styled.div`
  width: 320px;
  height: 100vh;
  overflow: auto;
  position: sticky;
  top: 0;
  transition: all 0.2s ease-in-out;
  box-shadow: 2px 0px 4px -1px rgb(0 0 0 / 20%),
    4px 0px 5px 0px rgb(0 0 0 / 14%), 1px 0px 10px 0px rgb(0 0 0 / 12%);
  z-index: 1200;
background: ${props => props.themeBase.layer["2.5"]};
  
`;

const SidebarSmallScreen = styled.div`
  ${(props) =>
    props.open
      ? `
    transform:translateX(0px);
    `
      : `
    transform:translateX(-350px);
    `}
  width:300px;
  height: 100%;
  overflow: auto;
  position: fixed;
background: ${props => props.themeBase.layer["2.5"]};
z-index: 1200;
  transition: all 0.2s ease-in-out;
`;

const Container = styled.div`
position:relative;
width:100%;
height:100%;
background: ${props => props.themeBase.layer["2.5"]};
padding:10px;
`

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
`;

const AvatarSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 20px;
`;

const MenuSection = styled.div`
  margin-top: 50px;
  width: 100%;
`;

const Sidebar = ({ closeSidebar, isSidebarOpen }) => {
  const themeBase = useThemeBase()
  const { pageStore, authStore } = useSelector((store) => store);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const menuArray = [
    { id: "dashboard", label: "Dashboard", icon: <DashboardIcon /> },
    { id: "feedbacks", label: "Feedbacks", icon: <FeedbackIcon /> },
    { id: "quiz", label: "Quiz", icon: <QuizIcon /> },
    { id: "stats", label: "Downloads", icon: <DownloadIcon /> },
    { id: "shareBusinessOpportunity", label: "Share Business Opportunity", icon: <BusinessIcon /> },
    { id: "watchOnDemand", label: "Watch On Demand", icon: <MovieIcon /> }
  ];

  const navigationHandler = (route) => {
    closeSidebar()
    navigate(route)
  }

  const SidebarMenu = (
    <List>
      {menuArray.map((menu, index) => (
        <ListItem
          key={"sidemenu" + index}
          disablePadding onClick={() => navigationHandler(menu.id)}
          sx={{
            borderBottom: pageStore?.currentPage?.id != menu.id && index < menuArray.length - 1 && "1px solid " + themeBase.common.light
          }}
        >
          <ListItemButton
            sx={{
              borderRadius: "4px",
              background:
                pageStore?.currentPage?.id == menu.id &&
                `${themeBase.primary.main} !important`,
              color: pageStore?.currentPage?.id == menu.id && "white",
              boxShadow: pageStore?.currentPage?.id == menu.id && themeBase.shadows.glow,
            }}
          >
            <ListItemIcon sx={{ color: pageStore?.currentPage?.id == menu.id && "white" }}>{menu.icon}</ListItemIcon>
            <ListItemText primary={menu.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  let content = (
    <Container className="overlay" themeBase={themeBase}>
      <TopBar>
        {isMobile && (
          <IconButton onClick={() => closeSidebar()}>
            <ArrowBackIcon />
          </IconButton>
        )}
      </TopBar>
      <AvatarSection>
        <Avatar
          style={{
            background: "lightpink",
            width: "150px",
            height: "150px",
            fontSize: "80px"
          }}
        >
          {authStore.authUser?.firstName?.substring(0, 1) + " " + authStore.authUser?.lastName?.substring(0, 1) || "?"}
        </Avatar>
        <Typography variant="h4">{authStore.authUser?.firstName + " " + authStore.authUser?.lastName}</Typography>
      </AvatarSection>
      <MenuSection>{SidebarMenu}</MenuSection>
    </Container>
  );

  return !isMobile ? (
    <SidebarLargeScreen themeBase={themeBase}>
      {content}
    </SidebarLargeScreen>
  ) : (
    <SidebarSmallScreen themeBase={themeBase} open={isSidebarOpen}>{content}</SidebarSmallScreen>
  );
};

export default Sidebar;
