import Parse from "./parse";
import * as actionTypes from "../store/actionTypes";

export const fetchShareBusinessOpportunityResponses = async () => {
  try {
    const shareBusinessOpportunityQuery = new Parse.Query("ShareBusinessOpportunities");
    let subscription = await shareBusinessOpportunityQuery.subscribe();
    let responses = await shareBusinessOpportunityQuery.limit(9999).descending("createdAt").find();
    responses = responses.map((response) => {
      return { ...response.attributes, id: response.id };
    });
    return responses
  } catch (error) {
    console.error(error);
    throw error;
  }
};

