import { Snackbar, Alert } from "@mui/material";
import React from "react";

const CustomAlert = ({type, open, onClose, duration, children}) => {
    return (
        <Snackbar open={open} autoHideDuration={ duration || 2000} onClose={onClose} >
            <Alert severity={type || "success"} sx={{ width: '100%' }} onClose={onClose} variant="filled" elevation={6}>
                {children}
            </Alert>
        </Snackbar >
    )
}

export default CustomAlert