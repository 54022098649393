import { Card } from "@mui/material";
import React from "react";
import useThemeBase from "../../hooks/useThemeBase";


const CustomizedCard = (props) => {
    const themeBase = useThemeBase()
    return (
        <Card style={{ background: themeBase.layer[1] }} className="overlay" {...props}>
            {props.children}
        </Card>
    );
};

export default CustomizedCard;
