import {
  Card,
  LinearProgress,
  linearProgressClasses,
  Skeleton,
  Typography
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import Avatars from "../widgets/avatars";
import mStyled from "@mui/material/styles/styled";
import useThemeBase from "../../hooks/useThemeBase";

const NoUsers = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
`;

const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 10px;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: Center;
  flex-direction: column;
  row-gap: 10px;
  justify-content:space-between;
`;
const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  width: mx-content;
  height: 100%;
  align-items: Center;
`;
const LeftContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: Center;
`;
const BarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: Center;
  column-gap: 10px;
`;

const AbsoluteContent = styled.div`
  // position: absolute;
  // top: 0;
  // right: 0;
  // display: flex;
  // justify-content: flex-end;
  // width: max-content;
  // height: min-content;
  // align-items: Center;
  // padding: 10px;
  // z-index: 1;
`;

const AvatarsContent = styled.div`
  margin: -20px auto -10px auto;
`;

const BorderLinearProgress = mStyled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    height: "6px",
    backgroundColor: theme.palette.common.light_transparent,
    borderRadius: 5
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "white"
  }
}));

const BoothCard = ({ location, openUsersList, liveUsers = [], totalLiveUsersCount = 0, loading }) => {
  const themeBase = useThemeBase()
  const content = (
    <Grid onClick={openUsersList} style={{ cursor: "pointer" }}>
      <Content>
        <Typography
          variant="h1"
          style={{ color: themeBase.primary.main, marginRight: "10px" }}
        >
          {liveUsers.length}
        </Typography>
        <AvatarsContent>
          <Avatars
            avatars={[...liveUsers.slice(0, 3)]}
            limit={3}
            total={liveUsers.length}
            location={location?.id}
          />
        </AvatarsContent>
        <Typography variant="h6" style={{ color: "inherit" }}>
        User{liveUsers?.length > 1 && "s"} Live in {location?.name}
        </Typography>
        <BarContainer>
          <BorderLinearProgress
            variant="determinate"
            value={totalLiveUsersCount > 0 ? Math.round((liveUsers.length/totalLiveUsersCount)*100) : 0}
            sx={{ height: "6px", borderRadius: "30px", width: "100%" }}
          />
          <Typography variant="body2">{totalLiveUsersCount > 0 ? +((liveUsers.length/totalLiveUsersCount)*100).toFixed(2) + "%" : 0 + "%"}</Typography>
        </BarContainer>
      </Content>
    </Grid>
  );

  return (
    <>
      <Card sx={{ background: themeBase.layer[1], boxShadow: "none", height: "100%" }}>
        {loading ? (
          <Skeleton sx={{ minHeight: "200px", margin: "auto 20px" }} />
        ) : (
          content
        )}
      </Card>
    </>
  );
};

export default BoothCard;
