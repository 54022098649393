import React, { useEffect, useState } from 'react'

const Timer = ({ startTime, duration = 0, isLive, style }) => {

    const [liveDuration, setLiveDuration] = useState(
        isLive ?
            Math.floor(new Date(new Date() - new Date(startTime)) / 1000) + duration :
            duration
    )


    useEffect(() => {
        if (isLive) {
            const interval = setInterval(() => {
                setLiveDuration(prev => prev + 1)
            }, 1000)
            return () => {
                clearInterval(interval)
            }
        }
    }, [isLive])

    return <span style={style}>
        {
            Math.floor((liveDuration / 60) / 60).toLocaleString('en-US', {
                minimumIntegerDigits: 2
              })
             + ":" + 
             (Math.floor(liveDuration / 60) % 60).toLocaleString('en-US', {
                minimumIntegerDigits: 2
              })
              + ":" + 
              (liveDuration % 60).toLocaleString('en-US', {
                minimumIntegerDigits: 2
              })
        }
    </span>
}

export default Timer