import Parse from "./parse";
import * as actionTypes from "../store/actionTypes";

export const fetchFeedbacks = async () => {
  try {
    const feedbackQuery = new Parse.Query("Feedback")
      .limit(9999)
      .descending("createdAt");
    let subscription = await feedbackQuery.subscribe();
    let feedbacks = await feedbackQuery.find();
    feedbacks = feedbacks.map((feedback) => {
      return { ...feedback.attributes, id: feedback.id };
    });
    return { subscription, feedbacks };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const handleLiveFeedbackSubscription = async (dispatch, response, __) => {
  try {
    let { subscription, feedbacks } = response;
    dispatch({ type: actionTypes.SET_FEEDBACKS, feedbacks });

    subscription.on("create", (obj) => {

      dispatch({
        type: actionTypes.UPDATE_FEEDBACKS,
        feedback: {...obj.attributes, id: obj.id}
      });
    });
  } catch (error) {
    console.error(error.message);
    dispatch({ type: actionTypes.LOCATION_ACTION_FAIL, error });
  }
};
