export const convertStringToId = string => string.replace(/\W/g, '_').toLowerCase()

export const convertIdToString = id => (
    id.replace(/_/g, ' ').split(" ").map((e) => e.charAt(0).toUpperCase() + e.slice(1)).join(" ")
)

export const toTitleCase = string => string.split(" ").map((e) => e.charAt(0).toUpperCase() + e.slice(1)).join(" ")

export const getDurationOfVisitor = ({ startTime, duration = 0, isLive, style }) => {


    let liveDuration = isLive ?
        Math.floor(new Date(new Date() - new Date(startTime)) / 1000) + duration :
        duration


    return Math.floor((liveDuration / 60) / 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2
    })
        + ":" +
        (Math.floor(liveDuration / 60) % 60).toLocaleString('en-US', {
            minimumIntegerDigits: 2
        })
        + ":" +
        (liveDuration % 60).toLocaleString('en-US', {
            minimumIntegerDigits: 2
        })

}