import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Rating,
  Skeleton
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CustomCard from "../widgets/custom-card";
import { useDispatch, useSelector } from "react-redux";
import { fetchFeedbacks, handleLiveFeedbackSubscription } from "../../api/feedbacks";
import useApi from "../../hooks/useApi";
import * as actionTypes from "../../store/actionTypes"
import CustomAlert from "../widgets/custom-alert";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  column-gap: 20px;
  margin: auto;
  padding: 20px 10px 0px 10px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
`;


const Feedbacks = () => {

  const dispatch = useDispatch();
  const { feedbackStore } = useSelector((store) => store);
  const { feedbacks } = feedbackStore;
  const subscriptionRef = useRef()
  const [error, setError] = useState(null)

  const [ { loading, refetch }] = useApi(fetchFeedbacks,{
    onSuccess: (res) => {
      if(res) handleLiveFeedbackSubscription(dispatch, res)
    },
    onError: (error) => setError(error)
  })

  const loadingElement = (
    <>
      <Skeleton sx={{ height: "100px", width: "80%", mx: "auto" }} />
      <Skeleton sx={{ height: "100px", width: "80%", mx: "auto" }} />
      <Skeleton sx={{ height: "100px", width: "80%", mx: "auto" }} />
    </>
  );

  return (
    <>
    <CustomCard header={`Feedbacks (${feedbacks.length})`}>
      {loading ? (
        loadingElement
        ) : (
          <Body>
          <List sx={{ width: "100%", }}>
            {feedbacks?.map((feedback, key) => {
              return (
                <React.Fragment key={key}>
                  <ListItem alignItems="flex-start" sx={{ paddingLeft: 0 }}>
                    <ListItemAvatar>
                      <Avatar>
                        {feedback.fullName
                          .split(" ")
                          ?.map((n) => n.substring(0, 1).toUpperCase())}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={feedback.fullName}
                      secondary={
                        <React.Fragment>
                          <span style={{ display: "block" }}>
                            <Rating
                              name="rating"
                              value={feedback.rating}
                              readOnly
                              />
                          </span>
                          {feedback.review}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  {key < feedbacks.length - 1 && (
                    <Divider variant="inset" component="li" />
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </Body>
      )}
    </CustomCard>
    <CustomAlert
        open={!!error}
        onClose={() => setError(null)}
        type="error"
        duration={2000}
      >
        Something went wrong
      </CustomAlert>
      </>
  );
};

export default Feedbacks;
