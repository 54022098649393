import React, { useState } from "react";
import Card from "../widgets/card";
import styled from "styled-components";
import { Button, Stack, Typography } from "@mui/material";
import { Add } from "@material-ui/icons";
import VideosList from "./videos-list";
import useApi from "../../hooks/useApi";
import { fetchWatchOnDemandConfiguration, updateWatchOnDemandConfiguration, uploadFile } from "../../api/watch-on-demand";
import Popup from "../widgets/popup";
import VideoForm from "./video-form";
import CustomAlert from "../widgets/custom-alert";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 180px);
  column-gap: 20px;
  margin: auto;
  padding: 20px 10px 0px 10px;
  overflow-y: auto;
`;

const WatchOnDemand = () => {
    const [watchOnDemand, setWatchOnDemand] = useState()
    const [openForm, setOpenForm] = useState()
    const [actionLoading, setActionLoading] = useState(false)
    const [error, setError] = useState()
    const [success, setSuccess] = useState(false)


    const [fetchWatchOnDemandApi] = useApi(fetchWatchOnDemandConfiguration, {
        onSuccess: (res) => {
            setWatchOnDemand(res)
        }
    })

    const createFileHandler = async (file, fileName, collectionId) => {
        const config = watchOnDemand.configuration
        try {
            const currentCollection = config && config[collectionId] ? config[collectionId] : { isDayAvailable: true, files: [] }
            setActionLoading(true)
            const fileURL = await uploadFile(file)
            await updateWatchOnDemandConfiguration({
                ...config,
                [collectionId]: {
                    ...currentCollection,
                    files: [...currentCollection.files, {
                        fileName: fileName,
                        fileURL,
                        makeAvailable: true,
                        id: new Date().toString()
                    }]
                }
            })
            setActionLoading(false)
            fetchWatchOnDemandApi.run()
            return true
        } catch (error) {
            setActionLoading(false)
            console.error(error)
            setError("Something went wrong!")
        }
    }

    return (
        <>
            <Card>
                <Stack sx={{ width: "100%", padding: "10px", gap: "10px" }}>
                    <Stack direction="row" sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                        gap: "10px"
                    }}>
                        <Typography variant="h5">Watch On Demand Videos</Typography>
                        <Button style={{ whiteSpace: "nowrap" }} startIcon={<Add />} onClick={() => setOpenForm(true)}>Add Video</Button>
                    </Stack>
                    <VideosList
                        watchOnDemand={watchOnDemand}
                        loading={fetchWatchOnDemandApi.loading}
                        refreshList={fetchWatchOnDemandApi.run}
                    />
                </Stack>
            </Card>
            {openForm && <VideoForm
                title="Add Video"
                config={watchOnDemand?.configuration}
                open={openForm}
                loading={actionLoading}
                error={error}
                onError={error => setError(error)}
                onCreate={createFileHandler}
                onSuccess={() => {
                    setSuccess(true)
                    fetchWatchOnDemandApi.run()
                }}
                onClose={() => setOpenForm(false)} />}
            <CustomAlert open={success} onClose={() => setSuccess(false)}>Added Successfully!</CustomAlert>
            <CustomAlert type="error" open={!!error} onClose={() => setError(null)}>{error}</CustomAlert>
        </>
    );
};

export default WatchOnDemand;
