import * as actionTypes from "../store/actionTypes";
import { USER_ROLE } from "../utils/constants";
import Parse from "./parse";

export const authLogin = async (data) => {
  return await Parse.User.logIn(data.username.toLowerCase(), data.password);
};

export const authLoginSuccess = (dispatch) => {
  const parseUserObj = Parse.User.current();
  if (parseUserObj.get("role") !== USER_ROLE.ADMIN) {
    const unauthorizedError = new Error();
    unauthorizedError.message = "Unauthorized User";
    throw unauthorizedError;
  }
  const userJSON = JSON.stringify(parseUserObj);
  dispatch({
    type: actionTypes.LOGIN_USER_SUCCESS,
    authUser: JSON.parse(userJSON)
  });
  localStorage.setItem("authUser", userJSON);
};

const verifyUserSession = async (sessionToken) => {
  try {
    let response = await Parse.User.become(sessionToken);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const logout = async (dispatch) => {
  try {
    await Parse.LiveQuery.close();
    await Parse.User.logOut();
    Parse.User.current();
    dispatch({ type: actionTypes.LOGIN_USER_SUCCESS, authUser: null });
    localStorage.setItem("authUser", null);
  } catch (error) {
    dispatch({ type: actionTypes.AUTH_ACTION_FAIL, error: error });
  }
};

export const verifyAndSetAuthUser = async (dispatch) => {
  try {
    dispatch({ type: actionTypes.AUTH_ACTION_START });
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (!authUser) await logout(dispatch);
    else {
      const sessionVerified = await verifyUserSession(authUser.sessionToken);
      if (sessionVerified)
        dispatch({ type: actionTypes.LOGIN_USER_SUCCESS, authUser });
      else await logout(dispatch);
    }
  } catch (error) {
    dispatch({ type: actionTypes.AUTH_ACTION_FAIL, error: error });
  }
};


export const getAppVersion = async() => {
  try {
    let adminVersionQuery = new Parse.Query('adminVersion');
    let adminVersionData = await adminVersionQuery.first();
    let adminVersion = "0.0";
    if(adminVersionData && adminVersionData.attributes.version){
      adminVersion = adminVersionData.attributes.version;
    }
    return adminVersion;
  } catch (error) {
    console.error(error);
    throw error;
  }
}