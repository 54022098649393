
export const darkThemeBase = {
  primary: {
    main: "#ff617e",
    dark: "#df4763",
    light: "#ff8198",
    light2: "#ffbfcb",
    contrast: "#fff"
  },

  secondary: {
    main: "#ee96ff",
    dark: "#b872c6",
    light: "#f0a8ff",
    light2: "#f6caff",
    contrast: "#222"
  },

  success: {
    main: "#96d17e"
  },

  error: {
    rgb: "255, 143, 143",
    main: "#ff8f8f",
    dark: "#c55b5b",
    light: "#ffa7a7",
    contrast: "#fff"
  },

  common: {
    light: "rgba(81, 81, 81, 1)",
    text: "rgb(255, 255, 255)",
  },

  colors: {
    1: "#d8b6ff",
    2: "#97c8ff",
    3: "#ffe9ab",
    4: "#fdc799",
    5: "#00c39a",
    6: "#f8fd99",
    7: "#99fde6"
  },

  layer: {
    1: "rgba(147, 120, 175, 0.1)",
    2: "rgba(147, 120, 175, 0.2)",
    2.5: "rgba(147, 120, 175, 0.25)",
    3: "rgba(147, 120, 175, 0.3)",
    4: "rgba(147, 120, 175, 0.4)",
    5: "rgba(147, 120, 175, 0.5)"
  },

  main: {
    background: "#222",
    constrast: "#fff"
  },

  shadows: {
    glow: "0px 3px 7px rgb(255 45 85 / 10%), 0px 5px 10px rgb(255 45 85 / 10%), 0px 10px 20px rgb(255 45 85 / 10%)"
  },

  spacing: {
    1: "5px",
    2: '10px',
    3: '15px',
    4: '20px',
  }
};
export const lightThemeBase = {
  primary: {
    main: "#ff617e",
    dark: "#df4763",
    light: "#ff8198",
    light2: "#ffbfcb",
    contrast: "#fff"
  },

  secondary: {
    main: "#ee96ff",
    dark: "#b872c6",
    light: "#f0a8ff",
    light2: "#f6caff",
    contrast: "#222"
  },

  success: {
    main: "#96d17e"
  },

  error: {
    rgb: "255, 143, 143",
    main: "#ff8f8f",
    dark: "#c55b5b",
    light: "#ffa7a7",
    contrast: "#fff"
  },

  common: {
    light: "rgba(81, 81, 81, 1)",
    text: "rgb(255, 255, 255)",
  },

  colors: {
    1: "#d8b6ff",
    2: "#97c8ff",
    3: "#ffe9ab",
    4: "#fdc799",
    5: "#00c39a",
    6: "#f8fd99"
  },

  layer: {
    1: "rgba(147, 120, 175, 0.1)",
    2: "rgba(147, 120, 175, 0.2)",
    2.5: "rgba(147, 120, 175, 0.25)",
    3: "rgba(147, 120, 175, 0.3)",
    4: "rgba(147, 120, 175, 0.4)",
    5: "rgba(147, 120, 175, 0.5)"
  },

  main: {
    background: "#222",
    constrast: "#fff"
  },

  shadows: {
    glow: "0px 3px 7px rgb(255 45 85 / 10%), 0px 5px 10px rgb(255 45 85 / 10%), 0px 10px 20px rgb(255 45 85 / 10%)"
  },

  spacing: {
    1: "5px",
    2: '10px',
    3: '15px',
    4: '20px',
  }
};


export const USER_ROLE = {
  EXHIBITOR: 0,
  VISITOR: 1,
  HELPDESK: 2,
  ADMIN: 3
};

export const LOCATIONS = [
  { id: "lobby", name: "Lobby", sessionId: "lobby_sessions" },
  { id: "auditorium", name: "Auditorium", sessionId: "audi_sessions" },
  { id: "networking", name: "Networking", sessionId: "networking_sessions" },
  { id: "engagement_zone", name: "Engagement Zone", sessionId: "engagement_area_sessions" },
  { id: "helpdesk", name: "Helpdesk", sessionId: "helpdesk_sessions" },
  { id: "selfie_booth1", name: "Selfie Booth", sessionId: "selfie_booth_sessions1" },
  { id: "watchOnDemand", name: "Watch On Demand", sessionId: "watchOnDemand_sessions" },
  // { id: "booth1", name: "Booth1", sessionId: "booth_sessions_1" },
  // { id: "booth2", name: "Booth2", sessionId: "booth_sessions_2" },
  // { id: "booth3", name: "Booth3", sessionId: "booth_sessions_3" },
  // { id: "booth4", name: "Booth4", sessionId: "booth_sessions_4" },
  // { id: "booth5", name: "Booth5", sessionId: "booth_sessions_5" },
  // { id: "booth6", name: "Booth6", sessionId: "booth_sessions_6" },
  // {
  //   id: "selfie_booth",
  //   name: "Selfie Booth",
  //   sessionId: "selfie_booth_sessions"
  // },
  // {
  //   id: "selfie_booth1",
  //   name: "Selfie Booth 1",
  //   sessionId: "selfie_booth_sessions1"
  // },
  // {
  //   id: "selfie_booth2",
  //   name: "Selfie Booth 2",
  //   sessionId: "selfie_booth_sessions2"
  // },
  // {
  //   id: "selfie_booth3",
  //   name: "Selfie Booth 3",
  //   sessionId: "selfie_booth_sessions3"
  // }
];

export const LOCAL_STORAGE_KEYS = {
  HAS_UPDATED: "hasUpdated",
}
