import "./App.css";
import { combineReducers, createStore, compose } from "redux";
import authReducer from "./store/reducers/auth";
import pageReducer from "./store/reducers/page";
import locationReducer from "./store/reducers/locations";
import feedbackReducer from "./store/reducers/feedbacks";
import tickerReducer from "./store/reducers/ticker";
import statsReducer from "./store/reducers/stats";
import quizReducer from "./store/reducers/quiz";
import sBOReducer from "./store/reducers/share-business-opportunity";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/layout/layout";
import Routing from "./routing";
import Theme from "./components/layout/theme";


function App() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const rootReducer = combineReducers({
    authStore: authReducer,
    pageStore: pageReducer,
    locationStore: locationReducer,
    feedbackStore: feedbackReducer,
    tickerStore: tickerReducer,
    statsStore: statsReducer,
    quizStore: quizReducer,
    sBOStore: sBOReducer,
  });

  const store = createStore(rootReducer, composeEnhancers());

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Theme>
          <Layout>
            <Routing />
          </Layout>
        </Theme>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
