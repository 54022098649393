import { styled, TextField, TextFieldPropsColorOverrides } from "@mui/material";
import React from "react";

const CustomInput = styled(TextField)(({ theme }) => ({
  input: {
    color:"#fff"
  }
}));

const Input = React.forwardRef((props, ref) => {

  return (
    <CustomInput 
    type={props.type}
    style={{width: "100%", color: "white"}}
    label={props.label} 
    variant="outlined" 
    name={props.name} 
    size="medium"
    ref={ref}
    {...props}

    />
  );
})

export default Input;
