import React, { useEffect, useState } from "react";
import Card from "../widgets/card";
import styled from "styled-components";
import { Autocomplete, Button, CircularProgress, Stack, TextField } from "@mui/material";
import { Add } from "@material-ui/icons";
import VideosList from "./videos-list";
import VideoInput from "./video-input";
import { updateWatchOnDemandConfiguration, uploadFile } from "../../api/watch-on-demand";
import CustomAlert from "../widgets/custom-alert";
import Popup from "../widgets/popup";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 180px);
  column-gap: 20px;
  margin: auto;
  padding: 20px 10px 0px 10px;
  overflow-y: auto;
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  align-items:center;
  justify-content:center;
  position:absolute;
  top:0;left:0;
  background: rgba(0,0,0,.2);
  backdrop-filter:blur(5px);
  z-index:1;
`;

const VideoForm = ({ collectionKey, config, onClose, open, title, loading, onError, onSuccess, onUpdate, onCreate, editConfig }) => {

    const [fileName, setFileName] = useState()
    const [collectionId, setCollectionId] = useState(editConfig?.collectionKey || "")
    const [file, setFile] = useState()

    const fileChangeHandler = (fileData) => {
        setFile(fileData)
    }

    if (editConfig?.file && !fileName) setFileName(editConfig.file.fileName)

    const checkValidation = () => {
        if (!fileName) {
            onError("Filename is empty")
            return false
        }
        if (!collectionId && !editConfig) {
            onError("GroupBy is empty")
            return false
        }
        if (typeof file !== "object" && !editConfig) {
            onError("File is empty")
            return false
        }
        return true

    }

    const submitHandler = () => {
        if (editConfig) {
            updateFileHandler()
        } else {
            createFileHandler()
        }
    }

    const updateFileHandler = async () => {
        if (!checkValidation()) return
        const response = await onUpdate(collectionKey, {
            ...editConfig.file,
            fileName,
        }, file)
        if (response) {
            onSuccess()
            onClose()
        }

    }
    const createFileHandler = async () => {
        if (!checkValidation()) return
        const response = await onCreate(file, fileName, collectionId)
        if (response) {
            onSuccess()
            onClose()
        }
    }

    return (
        <Popup title={title} open={open} onClose={() => !loading && onClose()}>
            <Stack style={{ width: "100%", maxWidth: "500px", margin: "20px auto", gap: "20px" }}>
                <Stack style={{ width: "100%" }}>
                    <TextField label="Filename" value={fileName} onChange={(e) => setFileName(e.target.value)} />
                </Stack>
                {!collectionKey && <Stack style={{ width: "100%" }}>
                    <Autocomplete
                        disablePortal
                        id="group-by"
                        options={config ? [...Object.keys(config), collectionId] : [collectionId]}
                        value={collectionId}
                        getOptionLabel={(option) => `${option}`}
                        onSelect={(e) => setCollectionId(e.target.value)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="GroupBy"
                                value={collectionId}
                                onChange={(e, _) => setCollectionId(e.target.value)}
                            />}
                    />
                </Stack>}
                <Stack style={{ width: "100%", alignItems: "center" }}>
                    <VideoInput
                        onChange={fileChangeHandler}
                        url={editConfig?.file?.fileURL || ""} />
                </Stack>
                <Stack direction="row" style={{ width: "100%", alignItems: "center", justifyContent: "flex-end" }}>
                    <Button variant="contained" onClick={submitHandler} >Submit</Button>
                </Stack>
                {loading && <LoadingWrapper><CircularProgress /></LoadingWrapper>}
            </Stack>
        </Popup>
    );
};

export default VideoForm;
