import * as actionTypes from "../actionTypes";

const initialState = {
  sBOResponses: [],
  loading: false,
  error: false
};



const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHARE_BUSINESS_OPPORTUNITY_ACTION_START:
      return { ...state, loading: true, error: false }

    case actionTypes.SHARE_BUSINESS_OPPORTUNITY_ACTION_FAIL:
      return { ...state, loading: false, error: action.error }

    case actionTypes.SET_SHARE_BUSINESS_OPPORTUNITY:
      return { ...state, loading: false, error: false, sBOResponses: action.responses }

    case actionTypes.UPDATE_SHARE_BUSINESS_OPPORTUNITY:
      return {
        ...state,
        loading: false,
        error: false,
        sBOResponses: [action.item, ...action.sBOResponses]
      }

    default:
      return state;
  }
};

export default reducer;
