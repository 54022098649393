import * as actionTypes from "../actionTypes";

const initialState = {
  answers: [],
  staticAnswers: [],
  loading: true,
  error: false
};


export const updateAnswers = (state, action) => {
  if (state.answers.filter(ans => ans.id === action.answer.id).length > 0) return { ...state }
  return { ...state, loading: false, error: null, answers: [action.answer, ...state.answers] };
};

export const updateStaticAnswers = (state, action) => {
  if (state.staticAnswers.filter(ans => ans.id === action.answer.id).length > 0) return { ...state }
  return { ...state, loading: false, error: null, staticAnswers: [action.answer, ...state.staticAnswers] };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.QUIZ_ACTION_START:
      return { ...state, loading: true, error: false }

    case actionTypes.QUIZ_ACTION_FAIL:
      return { ...state, loading: false, error: action.error }

    case actionTypes.SET_ANSWERS:
      return { ...state, loading: false, error: null, answers: action.answers };

    case actionTypes.UPDATE_ANSWERS:
      return updateAnswers(state, action);

    case actionTypes.SET_STATIC_ANSWERS:
      return { ...state, loading: false, error: null, staticAnswers: action.answers };

    case actionTypes.UPDATE_STATIC_ANSWERS:
      return updateStaticAnswers(state, action);

    default:
      return state;
  }
};

export default reducer;
