const VideoPlayer = ({ src, props }) => {
    return <video
        width="100%"
        controls
        src={src}
        autoPlay
        style={{ borderRadius: "4px" }}
        {...props}
    />
}
export default VideoPlayer