import React, { useEffect, useState } from "react";
import Popup from "../widgets/popup";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import styled from "styled-components";
import { Avatar, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../hooks/useApi";
import { getAllUsers, getAllUsersSuccess } from "../../api/location";
import useThemeBase from "../../hooks/useThemeBase";

const LiveBadge = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background: ${props => props.themeBase.success.main};
  border-radius: 50%;
`;
const RelativeContainer = styled.span`
  position: relative;
`;

const OnlineUsersList = ({ open, onClose, title }) => {
  const themeBase = useThemeBase()
  const { locationStore, authStore } = useSelector((store) => store);
  const dispatch = useDispatch();
  const { liveUsers, allUsers } = locationStore;

  const [usersRows, setUsersRows] = useState([]);

  const [allUsersApiProps] = useApi(getAllUsers, {
    onSuccess: (res) => {
      if (res) getAllUsersSuccess(dispatch, res, authStore?.authUser);
    },
    onlyOnEvent: true
  });

  useEffect(() => {
    updateUserRows();
  }, [allUsers]);

  const updateUserRows = () => {
    let users = allUsers?.map((user) => {
      return {
        ...user,
        status:
          liveUsers?.filter((liveUser) => liveUser.email === user.email).length >
          0,
        id: user.userId || user.id,
        phone: user.phone || "1234567890",
        email: user.email,
        iconLabel:
          user.firstName.substring(0, 1).toUpperCase() +
          user.lastName.substring(0, 1).toUpperCase()
      };
    });
    users?.sort((a, b) => Number(b.status) - Number(a.status))
    setUsersRows(users);
  };

  useEffect(() => {
    updateUserRows();
  }, [liveUsers?.length]);

  useEffect(() => {
    if (open) allUsersApiProps.run();
  }, [open]);

  const columns = [
    {
      field: "iconLable",
      disableExport: true,
      minWidth: 70,
      renderCell: (params) => {
        return (
          <RelativeContainer>
            <Avatar
              sizes="small"
              style={{ background: themeBase.primary.light2 }}
            >
              {params.row.iconLabel}
            </Avatar>
            {params.row.status && <LiveBadge themeBase={themeBase}></LiveBadge>}
          </RelativeContainer>
        );
      },
      headerName: ""
    },
    {
      field: "firstName",
      minWidth: 150,
      headerName: "First Name"
    },
    {
      field: "lastName",
      minWidth: 150,
      headerName: "Last Name"
    },
    {
      field: "status",
      minWidth: 150,
      headerName: "Status",
      renderCell: (params) =>
        params.row.status ? (
          <span style={{ color: themeBase.success.main }}>Online</span>
        ) : (
          <span style={{ color: themeBase.error.main }}>Offline</span>
        )
    },
    {
      field: "phone",
      minWidth: 150,
      headerName: "Phone No."
    },
    {
      field: "email",
      minWidth: 250,
      headerName: "Email"
    },
    {
      field: "workAddress",
      minWidth: 250,
      headerName: "Work Address"
    },
    {
      field: "company",
      minWidth: 250,
      headerName: "Company"
    },
    {
      field: "jobTitle",
      minWidth: 250,
      headerName: "Job Title"
    },
    {
      field: "jobRole",
      minWidth: 250,
      headerName: "Job Role"
    },
    {
      field: "designation",
      minWidth: 250,
      headerName: "Designation"
    },
    {
      field: "department",
      minWidth: 250,
      headerName: "Department"
    },
    {
      field: "interestArea",
      minWidth: 250,
      headerName: "Interest Area"
    },
    {
      field: "attendeeType",
      minWidth: 250,
      headerName: "Attendee Type"
    },
    {
      field: "country",
      minWidth: 250,
      headerName: "Country"
    },
    {
      field: "createdAt",
      minWidth: 250,
      headerName: "Created at",
      renderCell: (params) => {
        return new Date(params.row.createdAt).toLocaleDateString() + " - " + new Date(params.row.createdAt).toLocaleTimeString()
      }
    }
  ];

  return (
    <Popup open={open} onClose={onClose} title={title}>
      {allUsersApiProps.loading ? (
        <Skeleton sx={{ minHeight: "100px", width: "100%" }} />
      ) : (
        <div style={{ width: "100%", height: "70vh" }}>
          <DataGrid
            rows={usersRows}
            columns={columns}
            components={{
              Toolbar: GridToolbar
            }}
            componentsProps={{
              toolbar: {
                csvOptions: {
                  fileName: 'All Users Current Status',
                }
              }
            }}
          />
        </div>
      )}
    </Popup>
  );
};

export default OnlineUsersList;
