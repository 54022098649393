import React, { useState } from "react";
import { convertStringToId, toTitleCase } from "../../utils/helpers";
import SchemaForm from "./schema-form";

const schema = {
  type: "object",
  properties: {
    question: {
      type: "string"
    },
    questionType: {
      enum: ["radio", "multiple", "text", "slider"],
      enumNames: ["Single Choice", "Multiple Choice", "Descriptive", "Slider"],
      default: "text"
    },
    required: {
      type: "boolean",
      default: false
    }
  },

  dependencies: {
    questionType: {
      oneOf: [
        {
          properties: {
            questionType: {
              enum: ["text"]
            }
          },
          required: ["questionType"]
        },
        {
          properties: {
            questionType: {
              enum: ["radio", "multiple"]
            },
            options: {
              title: "Options",
              type: "array",
              items: {
                type: "string"
              }
            }
          },
          required: ["options", "questionType"]
        },
        {
          properties: {
            questionType: {
              enum: ["slider"]
            },
            min: {
              title: "Min Value",
              type: "number"
            },
            max: {
              title: "Min Value",
              type: "number"
            },
          },
          required: ["min", "max", "questionType"]
        }
      ]
    }
  },
  required: ["question", "questionType"]
};

const uiSchema = {
  "ui:options": {
    submitButtonOptions: {
      props: {
        disabled: false
      },
      label: false,
      norender: false,
      submitText: "Submit"
    }
  },
  question: {
    "ui:options": {
      label: false,
      widget: "textWidget",
      props: {
        label: "Question",
        multiline: true
      }
    }
  },
  questionType: {
    "ui:options": {
      widget: "select",
      label: false,
      props: {
        label: "Type"
      }
    }
  },
  min: {
    "ui:options": {
      widget: "textWidget",
      label: false,
      props: {
        label: "Min Value",
        type: 'number'
      }
    }
  },
  max: {
    "ui:options": {
      widget: "textWidget",
      label: false,
      props: {
        label: "Max Value",
        type: 'number'
      }
    }
  },
  required: {
    "ui:options": {
      widget: "switchWidget",
      label: false,
      props: {
        label: "Required"
      }
    }
  },
  options: {
    title: "Options",
    "ui:options": {
      widget: "optionsWidget",
      label: false
    }
  }
};

const initialData = {
  question: "",
  questionType: "",
  required: false,
  allowedMultiAttempts: false,
  options: []
};
const StaticQuestionForm = ({ onSubmit, onError, loading, formData, questionId }) => {
  const [currentData, setCurrentData] = useState(formData || initialData);

  const validations = (formData, errors) => {
    if (formData.question === "")
      errors.question.addError("Question is Required.");
    if (
      (formData.questionType === "radio" ||
        formData.questionType === "multiple") &&
      formData.options.length < 1
    )
      errors.options.addError("Option is Required.");

    if (
      (formData.questionType === "radio" ||
        formData.questionType === "multiple") &&
      formData.options.filter((option) => option === "").length > 0
    )
      errors.options.addError("Empty option is not allowed.");

    return errors;
  };

  const submitHandler = (data) => {
    const options =
      data?.questionType === "radio" || data?.questionType === "multiple"
        ? data.options.map((option) => {
          return {
            value: convertStringToId(option?.trim()),
            label: toTitleCase(option?.trim())
          };
        })
        : [];

    let questionObject = {
      question: data.question,
      questionType: data.questionType,
      required: data.required,
      min: data.min,
      max: data.max,
      options
    };

    onSubmit(questionObject, questionId);
  };

  return (
    <SchemaForm
      schema={schema}
      uiSchema={uiSchema}
      onSubmit={submitHandler}
      currentData={currentData}
      loading={loading}
      validations={validations}
    />
  );
};

export default StaticQuestionForm;
