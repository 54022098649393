
import React from "react"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { Button, IconButton, Menu, MenuItem } from "@mui/material"
import { useState } from "react"

const CustomMenu = ({options, icon, label, btnProps}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
    return <div>
        {label ? <Button {...btnProps} onClick={handleClick}>{label}</Button> : 
        <IconButton
        onClick={handleClick}
        >
            {icon ? icon : <MoreVertIcon />}
        </IconButton>}
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            {options.map((option, key) => (
                <MenuItem key={"options" + key} onClick={() => {
                    option.action()
                    handleClose()
                    }} >
                    {option.label}
                </MenuItem>
            ))}
        </Menu>
    </div>
}

export default CustomMenu